import { UserContextValue } from 'services/UserProvider'

class UserState {
  user?: UserContextValue

  getUser() {
    if (!this.user) {
      throw new Error('Missing user in UserState')
    }
    return this.user
  }
}

export const userState = new UserState()
