import { Location, NavigateFunction } from '@remix-run/react'

class RemixNavigationManager {
  private navigateFunction?: NavigateFunction

  private location?: Location

  update(navigate: NavigateFunction, location: Location) {
    this.navigateFunction = navigate
    this.location = location
  }

  navigate: NavigateFunction = (...params) => {
    if (!this.navigateFunction) {
      throw new Error(
        'Attempted navigation before RemixNavigationManager was updated'
      )
    }

    // @ts-ignore
    return this.navigateFunction(...params)
  }

  getLocation() {
    if (!this.location) {
      throw new Error(
        'Attempted getLocation before RemixNavigationManager was updated'
      )
    }

    return this.location
  }
}

export const remixNavigationManager = new RemixNavigationManager()
