import { v4 as uuid } from 'uuid'
import {
  amplitude,
  initAmplitude,
  updateAmplitudeUserProperties,
} from './platforms/amplitude'
import {
  Pixel as facebookPixel,
  setFacebookAdvancedMatching,
} from './platforms/facebook'
import { klaviyo } from './platforms/klaviyo'
import {
  serverSideAnalytics,
  ServerSideAnalyticsPlatform,
} from './ServerSideAnalytics'
import { ALL_UTM_PARAMS } from './platforms/utm'
import { intercomService } from './platforms/intercom'
import { initGoogleAnalytics } from './platforms/gtm'
import { setUserPhoneNumber } from '../User/phoneNumber'
import { getUrlParam } from '../../utils/UrlUtils'
import storageManager from '../StorageManager'
import { storeAttributionParams } from '../../utils/ApiUtils'
import { getSha256 } from '../../utils/cryptoUtils'
import SerialTaskRunner from '../SerialTaskRunner'
import { experimentManager } from '../ExperimentManager/ExperimentManager'

export const PRELOAD_URL_PARAM = 'preload'

const LAST_ATTRIBUTION_PARAMS_TIME_KEY = 'LastAttributionParamsTime'
const LAST_ATTRIBUTION_PARAMS_KEY = 'LastAttributionParams'
const LOCAL_STORAGE_KEY = 'analytics'
const USED_PROMO_CODE_KEY = 'usedPromoCode'
const USER_PHONE_NUMBER_KEY = 'userPhoneNumber'
const USER_COUNTRY_CODE_KEY = 'User Country Code'

export class Analytics {
  // Queued events are stored before the user is identified and fired FIFO after the user is identified via identifyUser()
  queuedEvents = []

  identified = false

  _trackOncePerSessionEvents = new Set()

  facebookPixelAlreadyTrackedInSesson = [] // this array reflect only one-time *session* (session mean that after the user exit the app the session restarts) events and not all time (like this.alreadyTracked)

  _userCountryCode = null

  isAmplitudeReady = false

  amplitudeTaskRunner = new SerialTaskRunner()

  init() {
    klaviyo.init()
    intercomService.init()
    initGoogleAnalytics()
  }

  initPromoCodeAnalytics() {
    if (storageManager.get(USED_PROMO_CODE_KEY) == null) {
      this.setUserProperties({ 'Used Promo Code': false })
      storageManager.set(USED_PROMO_CODE_KEY, false)
    }
  }

  syncStorage() {
    const alreadyTracked = Array.from(this.alreadyTracked)
    const alreadyTrackedPerId = Array.from(this.alreadyTrackedPerId)
    storageManager.set(LOCAL_STORAGE_KEY, {
      alreadyTracked,
      alreadyTrackedPerId,
    })
  }

  async identifyUser(userId) {
    // This makes sure we store amplitude's generic params on the correct userId.
    // This fixes discrepancies that happen when the localStorage is empty but the amplitude cookie exists.
    // The facebook browser, for example, empties the localStorage sometimes (but keeps the cookies).
    // In these cases we will create a new userId, but Amplitude will get the id from the cookie.
    // This will only affect generic params, since they are collected before we call amplitude.setUserId
    klaviyo.identifyUser(userId)
    window.dataLayer.push({
      userId,
    })

    intercomService.identifyUser(userId)
    await initAmplitude(userId)

    const utmParams = this.getUTMParams()
    const setParams = { ...utmParams, 'App Platform': this.getAppPlatform() }
    const unsetParams = []
    if (Object.keys(utmParams).length > 0) {
      storageManager.set(LAST_ATTRIBUTION_PARAMS_TIME_KEY, Date.now())
    } else {
      const lastAttributionParamsTime = storageManager.get(
        LAST_ATTRIBUTION_PARAMS_TIME_KEY
      )
      if (lastAttributionParamsTime) {
        const timePassed = Date.now() - lastAttributionParamsTime
        if (timePassed > 1000 * 60 * 60 * 24) {
          storageManager.remove(LAST_ATTRIBUTION_PARAMS_TIME_KEY)
          unsetParams.push(...ALL_UTM_PARAMS)
        }
      } else {
        unsetParams.push(...ALL_UTM_PARAMS)
      }
    }

    storeAttributionParams(userId, utmParams, document.referrer)
    updateAmplitudeUserProperties({
      set: setParams,
      unset: unsetParams,
    }).then(() => {
      this.isAmplitudeReady = true
      this.amplitudeTaskRunner.runTasks()
    })
    // save for later use when user enters email:
    storageManager.set(LAST_ATTRIBUTION_PARAMS_KEY, {
      username: userId,
      attributionParams: {
        utmParams,
        referrer: document.referrer,
      },
    })
    this._reportDelayedAnalyticsData()
  }

  _reportDelayedAnalyticsData() {
    this.identified = true
    this.queuedEvents.forEach(({ eventName, eventProperties, trackOnce }) => {
      if (trackOnce) {
        this.trackOnce(eventName, eventProperties)
      } else {
        this.track(eventName, eventProperties)
      }
    })
    this.queuedEvents = []
  }

  getAppPlatform() {
    // since pwa installed from play store has platform: web, we want to distinguish between web and android
    if (document.referrer?.startsWith('android-app://com.mixtiles.android')) {
      return 'Android'
    } else {
      return 'Web'
    }
  }

  getUTMParams() {
    const parsedUtmParams = {}
    for (const utmParam of ALL_UTM_PARAMS) {
      const utmValue = getUrlParam(utmParam)
      if (utmValue) parsedUtmParams[utmParam] = utmValue
    }
    return parsedUtmParams
  }

  setUserEmail({ email, isNewEmail, trackEventName, trackProperties = {} }) {
    const hashEmail = email ? getSha256(email) : null
    const emailProperties = {
      Email: email,
      'Hash Email': hashEmail,
      'Is New Email': isNewEmail,
      ...trackProperties,
    }
    this.setUserProperties(emailProperties)
    klaviyo.setEmail(email)
    klaviyo.setInitialUserId()
    klaviyo.setUserProperties(experimentManager.getUserProperties())
    intercomService.setEmail(email)
    this.track(trackEventName, emailProperties, {
      serverSideAnalyticsPlatforms: [
        ServerSideAnalyticsPlatform.Facebook,
        ServerSideAnalyticsPlatform.Klaviyo,
      ],
    })
    window.dataLayer.push({ userEmail: email })
    this.resendAttributionParams()
    setFacebookAdvancedMatching(email)
  }

  resendAttributionParams(attributionSurveyAnswer) {
    const existingAttributionParams = storageManager.get(
      LAST_ATTRIBUTION_PARAMS_KEY
    )
    if (existingAttributionParams) {
      storeAttributionParams(
        existingAttributionParams.username,
        existingAttributionParams.attributionParams.utmParams,
        existingAttributionParams.attributionParams.referrer,
        attributionSurveyAnswer
      )
    }
  }

  setPhoneNumber(number) {
    klaviyo.setPhoneNumber(number)
    setUserPhoneNumber(number)
    window.dataLayer.push({ [USER_PHONE_NUMBER_KEY]: number })
  }

  setUserCountryCode(countryCode) {
    this._userCountryCode = countryCode
  }

  async setUserProperties(userProperties) {
    const promise = updateAmplitudeUserProperties({ set: userProperties })
    intercomService.setUserProperties(userProperties)
    klaviyo.setUserProperties(userProperties)
    await promise
  }

  track(
    eventName,
    eventProperties = {},
    { serverSideAnalyticsPlatforms = [], eventID = uuid() } = {}
  ) {
    const propertiesWithCountry = this.addUserCountryCode(eventProperties)
    if (!this.identified) {
      this.queuedEvents.push({ eventName, eventProperties, trackOnce: false })
      return
    }
    try {
      if (window.KEYS.runtimeEnv !== 'production' && !window.KEYS.silent) {
        console.log(`[Event] ${eventName} - ${JSON.stringify(eventProperties)}`)
      }

      if (this.isAmplitudeReady) {
        amplitude.track(eventName, eventProperties)
      } else {
        this.amplitudeTaskRunner.addTask(async () => {
          amplitude.track(eventName, eventProperties)
        })
      }
      this.reportServerAnalytics(
        eventName,
        eventProperties,
        eventID,
        serverSideAnalyticsPlatforms
      )
      facebookPixel.trackCustom(eventName, propertiesWithCountry, { eventID })
      intercomService.track(eventName, eventProperties)
      klaviyo.track(eventName, { ...eventProperties, $event_id: eventID })
      window.dataLayer.push({
        event: eventName,
        props: propertiesWithCountry,
        eventID,
      })
    } catch (error) {
      console.error(error)
    }
  }

  addUtmParamsIfMissing(eventProperties) {
    try {
      const propsKeys = Object.keys(eventProperties)
      let props = eventProperties

      if (!propsKeys.some((k) => k.startsWith('utm_'))) {
        const utmParams = this.getUTMParams()
        if (Object.values(utmParams).some((v) => v !== undefined)) {
          props = { ...props, ...utmParams }
        }
      }

      return props
    } catch (error) {
      console.error(error)
      return eventProperties
    }
  }

  addUserCountryCode(eventProperties = {}) {
    return this._userCountryCode
      ? { ...eventProperties, [USER_COUNTRY_CODE_KEY]: this._userCountryCode }
      : eventProperties
  }

  reportServerAnalytics(
    eventName,
    eventProperties,
    eventID,
    serverSideAnalyticsPlatforms
  ) {
    const props = this.addUtmParamsIfMissing(eventProperties)
    serverSideAnalytics.track(
      eventName,
      props,
      eventID,
      serverSideAnalyticsPlatforms
    )
  }

  trackOnce(eventName, eventProperties) {
    if (!this.identified) {
      this.queuedEvents.push({ eventName, eventProperties, trackOnce: true })
      return
    }

    if (!this.alreadyTracked) {
      this.initAlreadyTracked()
    }

    if (this.wasTracked(eventName)) {
      return
    }
    this.track(eventName, eventProperties)
    this.setAsTracked(eventName)
  }

  trackOncePerId(eventName, id, eventProperties) {
    if (!this.alreadyTrackedPerId) {
      this.initAlreadyTracked()
    }

    if (this.wasTrackedPerId(eventName, id)) {
      return
    }
    this.track(eventName, eventProperties)
    this.setAsTrackedPerId(eventName, id)
  }

  trackScreenView(screenName) {
    if (getUrlParam(PRELOAD_URL_PARAM)) {
      // Do not log screen views in preload mode
      return
    }
    this.track('Screen View', {
      'Screen Name': screenName,
      Path: window.location.pathname,
    })
  }

  facebookPixelTrackOnceInSession(eventName, data = {}) {
    if (!this.facebookPixelAlreadyTrackedInSesson.includes(eventName)) {
      this.facebookPixelAlreadyTrackedInSesson.push(eventName)
      this.facebookTrack(eventName, data)
    }
  }

  facebookTrack(eventName, data = {}, eventID = uuid()) {
    const properties = this.addUserCountryCode(data)
    facebookPixel.track(eventName, properties, { eventID })
    this.reportServerAnalytics(eventName, properties, eventID, [
      ServerSideAnalyticsPlatform.Facebook,
    ])
  }

  // This method tracks regular client side analytics but only tracks once per session to the server.
  // This is useful for high-load, frequent actions, like "Photo Picked"
  trackWithOneTimeServerSide(
    eventName,
    eventProperties,
    serverSideAnalyticsPlatforms = []
  ) {
    const eventID = uuid()
    this.track(eventName, eventProperties, { eventID })
    if (
      (serverSideAnalyticsPlatforms || []).length &&
      !this._trackOncePerSessionEvents.has(eventName)
    ) {
      this.reportServerAnalytics(
        eventName,
        eventProperties,
        eventID,
        serverSideAnalyticsPlatforms
      )
      this._trackOncePerSessionEvents.add(eventName)
    }
  }

  trackFirstInteraction() {
    this.trackOncePerSession(
      'First Interaction',
      { 'Full URL': window.location.href },
      { serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Facebook] }
    )
  }

  trackEmailCaptureTermsOfUseTapped() {
    this.track('Email Capture Terms Of Use Tapped')
  }

  trackEmailCapturePrivacyPolicyTapped() {
    this.track('Email Capture Privacy Policy Tapped')
  }

  trackFirstOpen() {
    const promoCode = getUrlParam('promo')
    this.trackOnce('First Open', promoCode ? { promoCode } : {})
  }

  trackOncePerSession(event, eventPropertries = {}, options = {}) {
    if (!this._trackOncePerSessionEvents.has(event)) {
      this.track(event, eventPropertries, options)
      this._trackOncePerSessionEvents.add(event)
    }
  }

  wasTracked(eventName) {
    return this.alreadyTracked && this.alreadyTracked.has(eventName)
  }

  wasTrackedPerId(eventName, id) {
    return (
      this.alreadyTrackedPerId &&
      this.alreadyTrackedPerId.has(`${eventName}_${id}`)
    )
  }

  initAlreadyTracked() {
    if (storageManager.get(LOCAL_STORAGE_KEY) == null) {
      storageManager.set(LOCAL_STORAGE_KEY, { alreadyTracked: [] })
    }
    const { alreadyTracked, alreadyTrackedPerId } =
      storageManager.get(LOCAL_STORAGE_KEY) || {}
    this.alreadyTracked = new Set(alreadyTracked || [])
    this.alreadyTrackedPerId = new Set(alreadyTrackedPerId || [])
  }

  setAsTracked(eventName) {
    this.alreadyTracked.add(eventName)
    this.syncStorage()
  }

  setAsTrackedPerId(eventName, id) {
    this.alreadyTrackedPerId.add(`${eventName}_${id}`)
    this.syncStorage()
  }
}

export const _analytics = new Analytics()
